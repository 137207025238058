import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navi from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./nav.css";

import Dojo from "../../assets/brand/dojo-master-logo.png";

const Nav = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  return (
    <>
      <Navbar expand="lg" className="navbar">
        <Container>
          <Navbar.Brand href="#">
            <img
              src={Dojo}
              width="35"
              height="35"
              className="d-inline-block align-top me-3"
              alt="Dojo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            onClick={() => setShowOffcanvas(true)}
          />
          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
            placement="end"
          >
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <Navbar.Collapse id="basic-navbar-nav">
                <Navi className="me-auto body_text">
                  <Navi.Link href="#guide" className="me-3">
                    How it works
                  </Navi.Link>
                  <Navi.Link href="#testimonials" className="me-3">
                    Testimonials
                  </Navi.Link>
                  <Navi.Link href="#faq" className="me-3">
                    FAQ
                  </Navi.Link>
                </Navi>
                <a
                  href="https://waitforit.me/signup/567a2c3b"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn-header btn_text">
                    Browse classes
                  </button>
                </a>
              </Navbar.Collapse>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Nav;
