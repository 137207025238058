import React, { useState } from "react";
import "./faq.css";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";

import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";

const Faq = () => {
  const [toggleInstructorsFaq, setToggleInstructorsFaq] = useState(false);

  const handleToggle = (val) => {
    setToggleInstructorsFaq(val);
  };

  return (
    <section id="faq" className="faq-container">
      <Container className="d-flex flex-column align-items-center">
        <div className="header_2 faq-header">FAQ</div>
        <div className="justify-content-center">
          <ToggleSwitch
            isToggled={toggleInstructorsFaq}
            onToggle={handleToggle}
            id="faqToggleSwitch"
          />
        </div>
        {toggleInstructorsFaq ? (
          <Accordion defaultActiveKey="0" flush className="custom-accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="body_text_bold">
                How can I sign up?
              </Accordion.Header>
              <Accordion.Body className="body_text">
                Just use this link. It’s free!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="body_text_bold">
                How does it work?
              </Accordion.Header>
              <Accordion.Body className="body_text">
                See how it works section above.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="body_text_bold">
                What happens if my student doesn't show up?
              </Accordion.Header>
              <Accordion.Body className="body_text">
                Contact us. We’ll work to reschedule your lesson.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="body_text_bold">
                Why should I use Dojo?
              </Accordion.Header>
              <Accordion.Body className="body_text">
                Well, we promote and list your service for free. We bring new
                clients through your door, and only take a 15% fee once the
                lesson is booked. Once you speak to the client, we encourage you
                to develop your personal relationship with the client. If you
                would like to continue your transactions on Dojo, we’ll work
                with you to get you recurring revenue each month.We also do all
                the heavy lifting and figure out the logistics for your lesson,
                so you can just focus on the important stuff - teaching.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="body_text_bold">
                Where do lessons take place?
              </Accordion.Header>
              <Accordion.Body className="body_text">
                Lessons take place in a public location. If you or the student
                have access to private location you can use, we encourage you to
                use that as well.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ) : (
          // trainer faq
          <Accordion defaultActiveKey="0" flush className="custom-accordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="body_text_bold">
                Where do classes take place?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              Addresses vary by instructor and will be disclosed upon booking. 
              <br/><br/>
              Some instructors may offer at-home services, which you will be able to select upon booking. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className="body_text_bold">
                How much do lessons cost?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              Dojo uses credits, and the exact cost will vary by class. Classes can range from $50 USD - $250 USD. 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className="body_text_bold">
                How do credits work?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              After you choose a membership plan, credits can be used to book a class. The amount of credits needed to book varies by category, location, and type of classes. 
              <br/><br/>
              Dojo credits allow you to book at a wide variety of athletes, musicians, artists & more.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="body_text_bold">
                What kind of qualifications do Dojo instructors have?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              We only want to offer the best - that means the most interesting, informative classes from the best in their field. 
              <br/><br/>
              Some of our instructors range from professional athletes, grammy-nominated musicians, and professors with 25+ years experience.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="body_text_bold">
                What if I want to book a lesson for my kid? Is it safe?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              Absolutely! 
              <br/><br/>
              We personally vet the businesses and instructors we work with, so we know they’re the best of the best! That includes being safe, fun, and engaging for kids!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header className="body_text_bold">
                Why should I use Dojo?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              Whether you want to learn something new, find something fun to do, or pick up a new hobby -- we’re the place to go.
              <br/><br/>
              We connect you with a wide variety of interesting classes, saving you the time and effort of finding the best ones. 
              <br/><br/>
              Stay active, join a community, and keep learning! 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header className="body_text_bold">
                What if I want to cancel my membership? Are there refunds?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              Yes! We offer full refunds for any un-used credits, no questions asked. 
              <br/><br/>
              Try us today, risk-free! 100% satisfaction guaranteed!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header className="body_text_bold">
                What is your cancellation policy for a class?
              </Accordion.Header>
              <Accordion.Body className="body_text">
              TBD
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </Container>
    </section>
  );
};

export default Faq;
