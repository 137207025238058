import React, { useState, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import Slider from "react-slick";
import "./header.css";

import Container from "react-bootstrap/Container";
// import Card from "react-bootstrap/Card";

import BuckskillTennis from "../../assets/buckskill-tennis.jpg";
import Horseback from "../../assets/horseback.jpg";
import Skydive from "../../assets/hero-skydive.jpg"
import Boxing from "../../assets/boxing.jpg"
import Tennis from "../../assets/hero-tennis.jpg"

import SmDiagonal from "../../assets/Sm-Diagonal.svg";
import LgDiagonal from "../../assets/Lg-Diagonal.svg";


const heroSlides = [
  {
    id:1,
    img: BuckskillTennis
  },
  {
    id: 2,
    img: Horseback
  },
  {
    id: 3,
    img: Tennis
  },
  {
    id: 4,
    img: Skydive
  },
  {
    id: 5,
    img: Boxing
  }
]

const Header = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  return (
    <header>
      <Container className="header_container">
        <div className="lg-header-diagonal-container">
          <img src={LgDiagonal} alt="LgDiagonal" className="lg-diagonal" />
        </div>
        <TextAnimation currentSlide={currentSlide} />

        <div className="header-subhead subhead">
          <p>
            Find and book unique, fun, informative classes taught by the{" "}
            <span style={{ fontWeight: 700 }}>best in their field.</span>{" "}
            Perfect for both kids and adults!
          </p>
        </div>

        <a
          href="https://waitforit.me/signup/567a2c3b"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="btn-header btn_text">Browse classes</button>
        </a>
        <p className="label_text header-label">
          Book in minutes, cancel anytime.
        </p>

        <HeaderCarousel onSlideChange={handleSlideChange} />
        <div className="sm-header-diagonal-container">
          <img src={SmDiagonal} alt="Diagonal" className="sm-diagonal-svg" />
        </div>
      </Container>
    </header>
  );
};

/* ------- HEADER CAROUSEL ------- */
const HeaderCarousel = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const settings = {
    className: "center",
    infinite: true,
    centerPadding: 10,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: 5,
        },
      },
    ],
  };

  useEffect(() => {
    console.log('HeaderCarousel mounted');
    // Any additional setup or logging here
  }, []); // Empty dependency array means this runs once on mount


  return (
    <div className="hero-slider">
      <Slider {...settings}>
        {heroSlides.map((img, idx) => (
          // make one image larger
          <div key={img.id} className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <img src={img.img} alt={img.img} className="hero-card" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

/* ------- ORIGINAL TYPE ANIMATION ------- */
const TextAnimation = () => {
  return (
    <div className="display">
      {""}
      <TypeAnimation
        sequence={[
          "In-person classes, taught by ",
          5000, // how long it stays
          "A one-stop-shop to learn from ",
          5000,
          "Have fun and learn from ",
          5000,
          "Book new experiences, from ",
          5000,
          "Learn something new, from ",
          5000,
          "Learn anything, from ",
          5000,
        ]}
        wrapper="span"
        speed={150} //type speed
        repeat={Infinity}
        cursor={false}
      />
      <br />
      <span className="highlight">the best.</span>
    </div>
  );
};

export default Header;
