import React from "react";
import "./testimonials.css";
import Kent from "../../assets/kent.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import QuoteIcon from "../../assets/quote-icon.svg";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";

const Testimonials = () => {
  return (
    <section id="testimonials" className="testimonials-section">
      <Container className="testimonials-container">
        <Row>
         <img
            src={QuoteIcon}
            alt="Quote Icon"
            className="quote-icon img-fluid"
          ></img>


          <div className="header_2 text-center testimonial-title">
            Why our customers use Dojo.
          </div>
          <div className="centered-hr">
            <hr />
          </div>
        </Row>
        <Row>
          <TestimonialSlider />
        </Row>
      </Container>
    </section>
  );
};

// const NextBtn = (props) => {
//   const { className, onClick } = props;

//   return (
//     <div className={className} onClick={onClick}>
//       <IoIosArrowForward style={{color: "var(--color-N500)", fontSize:"45px"}} />
//     </div>
//   )
// }
const NextBtn = (props) => {
  const { className, onClick } = props;

  return (
    <div>
      <IoIosArrowForward
        style={{ color: "var(--color-N500)", fontSize: "45px" }}
        className={className}
        onClick={onClick}
      />
    </div> // when className and onClick are removed from div and placed in arrow component, it appropriately
    // removes the original arrows.... but inhibits changing the size?
  );
};

const PrevBtn = (props) => {
  const { className, onClick } = props;

  return (
    <div>
      <IoIosArrowBack
        style={{ color: "var(--color-N500)", width: "40px" }}
        className={className}
        onClick={onClick}
      />
    </div>
  );
};

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <NextBtn />,
    prevArrow: <PrevBtn />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
          autoplay: false,
        },
      },
    ],
  };
  return (
    <div className="testimonials-slider">
      <Slider {...settings}>
        {testimonialsData.map((t) => (
          <Card key={t.id} className="testimonial-card justify-content-center align-items-center">
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <Card.Text className="testimonial-card-text body_text">
              {t.review}
              </Card.Text>
              <Card.Img
                src={t.photo}
                alt="User photo"
                className="testimonial-card-img"
              />
              <Card.Text className="header_5 mb-1 mt-2">Kent</Card.Text>
              <Card.Subtitle className="body_text">
                {t.subtitle}
              </Card.Subtitle>
            </Card.Body>
          </Card>
        ))}
      </Slider>
    </div>
  );
};

const testimonialsData = [
  {
    id: 1,
    name: "Kent",
    photo: Kent,
    subtitle: "Tennis, Hobbyist, 55",
    review:
      "I came to Dojo to learn tennis, but I ultimately discovered a variety of other interesting lessons that I would otherwise never have considered.",
  },
  {
    id: 2,
    name: "Kent",
    photo: Kent,
    subtitle: "Tennis, Hobbyist, 55",
    review:
      "Dojo is a smooth, easy way to try a combination of interesting, informative lessons with top-tier professionals. Dojo is the place for people who want to continue learning",
  },
  {
    id: 1,
    name: "Kent",
    photo: Kent,
    subtitle: "Tennis, Hobbyist, 55",
    review:
      "I have played twice since our first lesson, and I already have better results and a lot more confidence in my forehand.",
  },
];

export default Testimonials;
