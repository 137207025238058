import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Landing from '../src/pages/Landing/Landing'
import Nav from '../src/components/nav/Nav'
import Footer from '../src/components/footer/Footer'

function App() {
  return (
  <>
  <Nav />
  <Landing />
  <Footer />
  </>
  );
}

export default App;
