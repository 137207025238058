import React from "react";
import "./trainers.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import TrainerCarousel from "../carousel/TrainerCarousel";

const Trainers = () => {
  return (
    <section id="trainers" className="trainers-section">
      <Container className="trainers-container">
        <Row className="trainer-text">
          <div className="header_2 trainer-title">
            Our top curated classes <span className="highlight">for summer 2024.</span>
          </div>
          <p className="subhead trainer-subhead">
            From Divison 1 athletes to experienced professionals, 
            our instructors are at the <span>top</span> of their field.
          </p>
        </Row>
        <Row>
          <TrainerCarousel />
        </Row>
      </Container>
    </section>
  );
};

export default Trainers;
