import React, {useEffect} from 'react'
import Slider from "react-slick";
import "./trainerCarousel.css"

import Card from 'react-bootstrap/Card'

import surfing from "../../assets/trainers/surfing.jpg"
import golf from "../../assets/trainers/southhampton-golf.jpg"
import western from "../../assets/trainers/western-riding.jpg"
import skydiving from "../../assets/trainers/skydiving.jpg"
import tennis from "../../assets/trainers/tennis.jpg"
import english from "../../assets/trainers/english-riding.jpg"
import pilates from "../../assets/trainers/pilates.jpg"
import bjj from "../../assets/trainers/jui-jitsu.jpg"
import soccer from "../../assets/trainers/soccer.jpg"
import cooking from "../../assets/trainers/cooking.jpg"
import sailing from "../../assets/trainers/sailing.jpg"


const TrainerCarousel = () => {
  const settings= { 
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

 useEffect(() => {
  console.log('Trainer Carousel mounted')
 }, [])

  return (
    <div className="trainer-slider">
<Slider {...settings}>
      {trainerData.map((t) => (
          <Card className="trainer-card text-white" key={t.id}>
          <Card.Img src={t.img} className="trainer-card-img" alt={t.name}/>
          <Card.ImgOverlay className="trainer-card-overlay text-center">
            <Card.Title className="trainer-card-title header_5">{t.name}</Card.Title>
            <Card.Text className="trainer-card-subtitle subhead_fs"> 
              {t.text}
            </Card.Text>
          </Card.ImgOverlay>
        </Card>
      ))}
</Slider> 
  </div>
  )
}

export default TrainerCarousel


const trainerData = [
  {
    id: 1,
    name: "Surfing",
    img: surfing,
    text: "20+ years experience, 15+ years instructing",
    alt: "Surfing instructor with 20+ years of experience"
  },
  {
    id: 2,
    name: "Golf",
    img: golf,
    text: "Upgrade your golf game with a PGA Pro",
    alt: "Golf lesson with a PGA Pro"
  },
  {
    id: 3,
    name: "Western Horseback Riding",
    img: western,
    text: "Oldest cattle ranch in the U.S.",
    alt: "Western horseback riding lesson with the oldest cattle ranch in the U.S."
  },
  {
    id: 4,
    name: "Skydiving",
    img: skydiving,
    text: "Experienced instructors, coastal views, easy access",
    alt: "Skydiving instructor with coastal views"
  },
  {
    id: 5,
    name: "Premier Tennis ",
    img: tennis,
    text: "Learn tennis with a current pro",
    alt: "Tennis instructor who is a current pro"
  },
  {
    id: 6,
    name: "Soccer",
    img: soccer,
    text: "Division 1 soccer player",
    alt: "Soccer instructor who is a Division 1 player"
  },
  {
    id: 7,
    name: "English Horseback Riding",
    img: english,
    text: "Learn equestiran riding from pros",
    alt: "English horseback riding instructor"
  },
  {
    id: 8,
    name: "Pilates",
    img: pilates,
    text: "Pilates in the Hamptons",
    alt: "Pilates instructor in the Hamptons"
  },
  {
    id: 9,
    name: "Jui-Jitsu",
    img: bjj,
    text: "Learn Jiu-Jitsu with Black Belts",
    alt: "Jiu-Jitsu instructor who is a Black Belt"
  },
  {
    id: 10,
    name: "Cooking",
    img: cooking,
    text: "Learn to cook from an Executive Chef",
    alt: "Cooking instructor who is an Executive Chef"
  },
  {
    id: 11,
    name: "Sailing",
    img: sailing,
    text: "Take an intro to sailing class",
    alt: "Sailing instructor for an intro class"
  },
]