import React, { useState } from "react";
import Slider from "react-slick";
import "./guide.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ToggleSwitch from "../toggleSwitch/ToggleSwitch";

// import assets
import Num1 from "../../assets/number-1.svg";
import Num2 from "../../assets/number-2.svg";
import Num3 from "../../assets/number-3.svg";
import Num4 from "../../assets/number-4.svg";
import M1 from "../../assets/client-step1.svg";
import M2 from "../../assets/client-step2.svg";
import M3 from "../../assets/client-step3.svg";
import M4 from "../../assets/client-step4.svg";
import M5 from "../../assets/trainer-step1.svg";
import M6 from "../../assets/trainer-step2.svg";
import M7 from "../../assets/trainer-step3.svg";
import M8 from "../../assets/trainer-step4.svg";
import TrainerBg from "../../assets/bg-trainer-guide.svg";
import ClientBg from "../../assets/bg-client-guide.svg";

const Guide = () => {
  const [toggleInstructorsGuide, setToggleInstructorsGuide] = useState(false);

  const handleToggle = (val) => {
    setToggleInstructorsGuide(val);
  };

  // background image styling and rendering
  const backgroundImage = toggleInstructorsGuide
    ? `url(${TrainerBg})`
    : `url(${ClientBg})`;

  return (
    <section
      id="guide"
      style={{
        backgroundImage,
        backgroundSize: "cover",
        backgroundPosition: "center 75px",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container className="guide-container">
        <div className="header_1 text-center guide-title">
          How it <span className="highlight">works.</span>
        </div>

        <div>
          <p className="text-center subhead guide-subhead">
            A one-stop-shop for new, fun experiences. Don’t see what you want?
            <br /> Tell us - we’ll help you find it.
          </p>
        </div>

        <div className="d-flex justify-content-center">
          <ToggleSwitch
            isToggled={toggleInstructorsGuide}
            onToggle={handleToggle}
            id="guideToggleSwitch"
          />
        </div>

        {toggleInstructorsGuide ? (
          /* ---------- TRAINER VIEWS ---------- */
          <>
            {guideDataTrainer.map((guide, idx) => (
              <Row key={guide.id} className="mx-auto mb-4 desktop">
                <Col xs={12} md={6} lg={6} xl={6}>
                  <div className="media-container">
                    {guide.mediaType === "image" ? (
                      <img
                        src={guide.mediaSrc}
                        alt={guide.title}
                        className="guide-image"
                        width="754"
                        height="342"
                      />
                    ) : (
                      <video
                        controls
                        width="754"
                        height="342"
                        autoPlay
                        muted
                        loop
                      >
                        <source src={guide.mediaSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                </Col>
                <Col
                  xs={12}
                  md={1}
                  lg={1}
                  xl={1}
                  className="text-center num-column-trainer"
                >
                  <img
                    src={guide.num}
                    alt={guide.title}
                    className="img-fluid"
                  />
                </Col>
                <Col xs={12} md={5} lg={5} xl={5} className="text-left">
                  <div className="header_4">{guide.title}</div>
                  <div className="body_text">
                    {" "}
                    {guide.text.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                </Col>
              </Row>
            ))}
            <Row className="mx-auto mb-4">
              <Col
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className="justify-content-center"
              >
                <Row className="mobile">
                  <div className="mobile">
                    <GuideCarousel guideData={guideDataTrainer} />
                  </div>
                </Row>
                <div className="trainer-start">
                  <Row>
                    <div className="start-block header_2">
                      Ready to start Training?
                    </div>
                  </Row>
                  <Row className="justify-content-center">
                    <button className="btn-start-trainer btn_text">
                      Try it free today!
                    </button>
                  </Row>
                  <Row>
                    <p className="label_text">
                      Get started in minutes, no credit card needed.
                    </p>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          /* ---------- STUDENT VIEWS ---------- */
          <>
            {guideDataTrainee.map((guide, idx) => (
              <Row key={guide.id} className="mx-auto mb-4 desktop">
                <Col
                  xs={12}
                  md={1}
                  lg={1}
                  xl={1}
                  className="text-center num-column-trainee mt-4" // change all ref to client later
                >
                  <img
                    src={guide.num}
                    alt={guide.title}
                    className="img-fluid"
                  />
                </Col>
                <Col xs={10} md={5} lg={5} xl={5} className="text-left mt-4">
                  <div className="header_4">{guide.title}</div>
                  <div className="body_text">
                    {guide.text.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                </Col>

                <Col xs={12} md={6} lg={6} xl={6}>
                  <div className="media-container">
                    {guide.mediaType === "image" ? (
                      <img
                        src={guide.mediaSrc}
                        alt={guide.title}
                        className="guide-image"
                        width="754"
                      />
                    ) : (
                      <video
                        controls
                        width="754"
                        height="342"
                        autoPlay
                        muted
                        loop
                      >
                        <source src={guide.mediaSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                </Col>
              </Row>
            ))}
            <Row className="mx-auto mb-4">
              <Col
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className="justify-content-center"
              >
                <Row className="mobile">
                  <div className="mobile">
                    <GuideCarousel guideData={guideDataTrainee} />
                  </div>
                </Row>
                <div className="trainee-start">
                  <Row>
                    <div className="start-block header_2">
                      Best summer ever.
                    </div>
                  </Row>
                  <Row className="justify-content-center">
                    <a
                      href="https://waitforit.me/signup/567a2c3b"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="btn-start-trainee btn_text">
                        Browse classes
                      </button>
                    </a>
                  </Row>
                  <Row>
                    <p className="label_text">Book in minutes.</p>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </section>
  );
};

const GuideCarousel = ({ guideData }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {guideData.map((guide, idx) => (
        <>
          <Row >
            <div className="media-container-mobile">
              <img
                src={guide.mediaSrc}
                alt={guide.title}
                className="guide-image"
                width="754"
                height="342"
              />
            </div>
          </Row>
          <Row className="text-container-mobile">
            <Col xs={3} className="num-column">
              <img src={guide.num} alt={guide.title} className="img-fluid" />
            </Col>
            <Col xs={9}>
              <div className="header_4">{guide.title}</div>
              <div className="body_text">
                {" "}
                {guide.text.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
        </>
      ))}
    </Slider>
  );
};

export default Guide;

const guideDataTrainee = [
  {
    id: 1,
    num: Num1,
    title: "Browse our classes",
    text: "Browse our wide variety of classes. From extreme sports to cooking and the arts - we have it all.\n\nDon't see what you want? Tell us & we'll find it for you.",
    mediaType: "image",
    mediaSrc: M1,
  },
  {
    id: 2,
    num: Num2,
    title: "Choose your plan",
    text: "Pick your preferred membership tier.\n\nCancel anytime. Full refund on any un-used credits.",
    mediaType: "image",
    mediaSrc: M2,
  },
  {
    id: 3,
    num: Num3,
    title: "Book a class",
    text: "We handle all the scheduling & payments, so you can rest easy. All you have to do is show up!",
    mediaType: "image",
    mediaSrc: M3,
  },
  {
    id: 4,
    num: Num4,
    title: "Continue learning!",
    text: "Loved your teacher? We'll help you schedule your next lesson.\n\nWant do experience something new? Book from one of our 30+ unique offerings.",
    mediaType: "image",
    mediaSrc: M4,
  },
];

const guideDataTrainer = [
  {
    id: 1,
    num: Num1,
    title: "Apply to join, in minutes. (It's free)",
    text: "Tell us what you would like to teach and your qualifications.",
    mediaType: "image",
    mediaSrc: M5,
  },
  {
    id: 2,
    num: Num2,
    title: "Chat with us!",
    text: "Share your experience with us and see if we’re a good fit for your goals.\n\nWe'll answer any questions you have",
    mediaType: "image",
    mediaSrc: M6,
  },
  {
    id: 3,
    num: Num3,
    title: "Create your listing.",
    text: "Set your own price and schedule.\n\nWe'll promote you and do all the heavy lifting to connect you with a student.\n\nWe only take a fee once a student books your lesson",
    mediaType: "image",
    mediaSrc: M7,
  },
  {
    id: 4,
    num: Num4,
    title: "Teach, get paid, repeat.",
    text: "Teach your lesson. Give feedback.\n\nIf your student enjoyed their lessons, we'll figure out what frequency they'd like to learn with you. Start to earn prdictable monthly revenue!\n\nIf you face any issues with the lesson, just let us know, and we'll sort it all out.",
    mediaType: "image",
    mediaSrc: M8,
  },
];
