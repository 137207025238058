import React, { useState, useRef } from "react";
import "./contact.css";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// import isEmail from "validator/lib/isEmail"
// import isMobilePhone from "validator/lib/isMobilePhone"
import validator from "validator";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validateEmail = (e) => {
    const email = e.target.value;

    if (!validator.isEmail(email)) {
      setEmailError("Invalid Email Address");
    } else {
      setEmailError("");
    }
  };

  const validatePhone = (e) => {
    const phone = e.target.value;

    if (!validator.isMobilePhone(phone)) {
      setPhoneError("Invalid Phone Number");
    } else {
      setPhoneError("");
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_q1aobha",
        "dojo_contact_form",
        form.current,
        "oiL8uJMDsFCw4fH5J"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <section id="contact" className="contact-section">
      <Container className="contact-container">
    
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <div className="header_1 white-text text_center contact-title">
              Learn anything, anywhere, at your pace.
            </div>
            <p className="white-text">
              Keep your growth constant. Maximize your potential today.
            </p>
          </div>


        {/* <Row className='d-flex align-items-center'>
          <Col className='contact-text' >
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            <div className="header_1 white-text text_center contact-title">
              Learn anything, anywhere, at your pace.
            </div>
            <p className="white-text">
              Keep your growth constant. Maximize your potential today.
            </p>
          </div>
          </Col> */}
        {/* <Col xs={12} sm={6} md={6} lg={6} xl={6}>
            <Form ref={form} onSubmit={sendEmail} className="custom-form form-font">
              <Form.Group className="mb-3">
                <Form.Label className="form-font-label">Name*</Form.Label>
                <Form.Control type="name" name="name" placeholder="Jane Doe" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-font-label">
                  Email address*
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="email@email.com"
                  onChange={(e) => validateEmail(e)}
                />
                <Form.Text style={{ color: "var(--color-error-500)" }}>
                  {emailError}
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-font-label">
                  Phone number*
                </Form.Label>
                <Form.Control
                  type="phone"
                  name="phone"
                  placeholder="111-222-3333"
                  onChange={(e) => validatePhone(e)}
                />
                <Form.Text style={{ color: "var(--color-error-500)" }}>
                  {phoneError}
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-font-label">
                  Desired activities*
                </Form.Label>
                <Form.Control
                  type="activity"
                  name="activity"
                  placeholder="Tennis, bowling, singing, etc"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="form-font-label">Zip Code</Form.Label>
                <Form.Control type="zip" name="zip" placeholder="12345" />
              </Form.Group>
              <Form.Group>
                <Form.Label className="form-font-contact-label">
                  Contact me via:
                </Form.Label>
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    <Form.Check
                      inline
                      label="Email"
                      name="contactMethod"
                      type={type}
                      id={`inline-${type}-1`}
                      className="custom-checkbox-checked form-font-check"
                    />
                    <Form.Check
                      inline
                      label="Text"
                      name="contactMethod"
                      type={type}
                      id={`inline-${type}-2`}
                      className="custom-checkbox-checked form-font-check"
                    />
                    <Form.Check
                      inline
                      label="Call"
                      name="contactMethod"
                      type={type}
                      id={`inline-${type}-3`}
                      className="custom-checkbox-checked form-font-check"
                    />
                  </div>
                ))}
              </Form.Group>
              <Button type="submit" className="btn-form mx-auto d-block">
                Find me a trainer!
              </Button>
              <div className="text-center">
                <Form.Text className="label_text form-text">
                  No credit card needed! Try it free today.
                </Form.Text>
              </div>
            </Form>
          </Col> */}
        {/* </Row> */}
        {/* </div> */}
      </Container>
    </section>
  );
};

export default Contact;
